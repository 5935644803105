import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import TRANSLATIONS_EN from "./language/en.json";
import TRANSLATIONS_IT from "./language/it.json";



i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            it: {
                translation: TRANSLATIONS_IT
            }
        },
        fallbackLng: 'en',
        lng: sessionStorage.getItem("lang") || "en",
        keySeparator: false,

        interpolation: {
            escapeValue: true
        }
    });

export default i18n;