import uaMobile from "is-mobile";

export const isMobile = () => uaMobile({ ua: navigator?.userAgent }) || window.innerWidth < 768;

export const getConfiguration = (configurations) => {
  return isMobile() ? configurations.find((item) => item?.mobile) : configurations?.find((item) => !item?.mobile);
};

export const showGamesByDelay = ({ time, providerIndex }) => {
  let previousTime = time;
  const arrList = document.getElementsByClassName("games-container");
  let length = arrList?.length;
  let current = providerIndex || 0;

  const interval = setInterval(() => {
    let arr = document.getElementsByClassName("games-container")[current]?.children;
    if (previousTime <= arr?.length && arr[previousTime]) {
      arr[previousTime].style.display = "block";
      previousTime++;
    } else if (!providerIndex && current < length) {
      current++;
      previousTime = time;
    } else {
      clearInterval(interval);
    }
  }, 0);
};
