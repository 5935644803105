import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import { toast } from "react-toastify";

import * as Controller from "../../../helpers/Controller";

import "./index.css";
import { useEffect } from "react";

export const DepositModal = (props) => {
  const { t } = useTranslation();
  const { account, hideModal } = props;
  const [qrCode, setQrCode] = useState();
  const [bonusForDeposit, setBonusForDeposit] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState();
  const [depositProviders, setDepositProviders] = useState();
  const [activeDespositProvider, setActiveDepositProvider] = useState();

  useEffect(() => {
    Controller.GetPaymentConfiguration("deposit")
      .then((data) => {
        setIsLoading(false);
        setDepositProviders(data);
        setActiveDepositProvider(data[0]);
      })
      .catch(() => setDepositProviders([]));
  }, []);

  const getQRcode = async (currency) => {
    setIsLoading(true);
    setActiveCurrency(currency);

    const request = {
      amount: 0,
      gameID: "0",
      language: "en",
      userID: account.id + "",
      username: account.email,
      additionalInfo: currency,
      currency: account.currency,
      getBonus: bonusForDeposit,
    };

    try {
      const result = await Controller.GetDepositUrl(activeDespositProvider, request).then((data) => JSON.parse(data.data));

      if (result.address == "Validation error") {
        throw new Error();
      }

      setIsLoading(false);
      setQrCode(result);
    } catch (error) {
      setIsLoading(false);
      setQrCode(null);
      toast.error(t("Something goes wrong"), {
        autoClose: 5000,
        draggable: true,
        theme: "colored",
        closeOnClick: true,
        progress: undefined,
        position: "top-right",
        hideProgressBar: false,
      });
    }
  };

  const selectActineProvider = (provider) => {
    setActiveDepositProvider(provider);
    setActiveCurrency(null)
    setQrCode(null)
  };

  return (
    <>
      <div className="w3-container">
        <div className="desposit-modal w3-modal">
          <div className="w3-modal-content w3-animate-top w3-card-4">
            <div className="deposit-modal-header">
              <span className="deposit-modal-title">{t("Deposit")}</span>
              <CloseOutlined style={{ fontSize: "80%", color: "black" }} onClick={() => hideModal()} />
            </div>
            <div className="deposit-modal-contnet">
              {!depositProviders || isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-100 ">
                  <div className="loader" />
                </div>
              ) : depositProviders.length ? (
                <>
                  <div className="left-side">
                    <div className="curreny">
                      <p className="deposit-title">{t("Currency")}</p>
                      <Input value={account.currency} disabled />
                    </div>
                    <div className="payment-methods">
                      <p className="deposit-title">{t("allPaymentMethods")}</p>
                      <div className="providers-grid">
                        {depositProviders.map((provider, index) => (
                          <div key={provider.name + index} className="provider-container" onClick={() => selectActineProvider(provider)}>
                            <div className={`provider ${activeDespositProvider?.name === provider.name ? "active" : ""}`}>
                              <img src={provider.logo?.light} alt="Provider image" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="right-side">
                    <p>{t("easyDeposit")}</p>
                    <p>{t("chooseMethod")}</p>
                    <div className="providers-grid">
                      {activeDespositProvider?.depositConfigs?.cryptoCurrency.map((currency) => (
                        <div key={currency.name} className="provider-container" onClick={() => getQRcode(currency.name)}>
                          <div className={`provider  ${currency.name === activeCurrency ? "active" : ""}`}>
                            <img src={currency.logo?.light} alt="Provider image" />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      {qrCode && (
                        <section className="qr-section">
                          <span>{t("qrTitle")} </span>
                          <img src={"data:image/png;base64," + qrCode?.qrCode} className="w-25" />
                          <span>Send any amount of {activeCurrency} (plus miner fee) to</span>
                          <p> {qrCode?.address}</p>
                          <button className="copy-button" onClick={() => navigator.clipboard.writeText(qrCode?.address)}>
                            {t("copyAddress")}
                          </button>
                        </section>
                      )}
                    </div>
                    {!qrCode &&
                      <div style={{ display: "flex", alignItems: "center", columnGap: "10px", marginTop: "15px", fontSize: 14 }}>
                      <input type="checkbox" id="bonus-checkbox" style={{ width: "18px", height: '18px', borderRadius: 0 }} checked={bonusForDeposit} onChange={(e) => setBonusForDeposit(e.target.checked)} />
                      <label htmlFor="bonus-checkbox">I want to receive bonus</label>
                    </div>}
                  </div>
                </>
              ) : (
                <div className="no-data">
                  <span className="deposit-title">No Aviliable Data</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
