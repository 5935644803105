import React from "react";
import { useTranslation } from "react-i18next";

const AlertModal = (props) => {
  const { t } = useTranslation();

  const { hideModal, title, message, btnMessage } = props;

  const HideModal = () => {
    hideModal();
  };

  return (
    <div className="error-modal">
      <div className="modal-dialog modal-dialog-error w3-animate-top">
        <div className="modal-content-deposit modal-content-error">
          <div className="modal-header-deposit modal-header-error">
            <button className="close" type="button" onClick={() => HideModal()}>
              <i className="fas fa-times"></i>
            </button>
            {title ? <h1 className="modal-title-deposit error-modal-title">{t(title)}</h1> : <></>}
          </div>
          <div className="modal-body-deposit">
            <div className="modal-error-body">
              <div className="alert-error center">
                <i className="far fa-check-circle primarycheck"></i>
              </div>
              <div className="message center margin20">{t(message)}</div>
              <button className="btn primary padding-20 w100 margin20" style={{ height: "41px" }} onClick={() => HideModal()}>
                <b>{btnMessage ? t(btnMessage) : t("CLOSE")}</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
