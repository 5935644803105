import React, { useState, createContext, useEffect } from 'react';

export const ConfigurationContext = createContext();

export const ConfigurationContextProvider = props => {
    const [configuration, setConfiguration] = useState([]);

    useEffect(() => {
        setConfiguration(props?.configuration)
    }, [props.configuration])

    return (
        <ConfigurationContext.Provider value={{ configuration, setConfiguration }}>
            {props?.children}
        </ConfigurationContext.Provider>
    );
}