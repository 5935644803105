import React, { useState } from "react";
import "./recovery.css";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";
import InputValidationForm from "../../InputValidationForm.jsx";
import { SharedButton as Button } from "../../shared/Button/index";
import * as Controller from "../../../helpers/Controller";
import { strongPassword, perfectPassword, mediumPassword } from "../../../helpers/index";

export const Recovery = () => {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState();
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [passwordPrivate, setPasswordPrivate] = useState({
    percent: 0,
    title: "AWFUL",
    status: "exception",
  });

  const passwordProgress = () => {
    perfectPassword.test(newPassword)
      ? setPasswordPrivate({
          title: "perfect",
          percent: 100,
          progressColor: "#8fc118",
        })
      : strongPassword.test(newPassword)
      ? setPasswordPrivate({
          title: "strong",
          percent: 70,
          progressColor: "#1db7e7",
        })
      : mediumPassword.test(newPassword)
      ? setPasswordPrivate({
          title: "weak",
          percent: 50,
          progressColor: "#fbd01f",
        })
      : setPasswordPrivate({
          title: "awful",
          percent: 20,
          progressColor: "#e06262",
        });
  };

  const onChange = (e, space) => {
    if (e?.target?.value.indexOf(" ") === -1 || space) setNewPassword(e?.target?.value);
    else e.target.value = e?.target?.value.slice(0, -1);
    passwordProgress();
  };

  const resetPassword = () => {
    if (passwordValid) {
      const code = window.location.pathname.split("/")[2];
      Controller.ResetPassword(code, newPassword).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <div className="recovery-page-container">
      <div className="recovery-page-content">
        <div className="recovery-page-title">Change your password</div>
        <div className="recovery-input-container">
          <InputValidationForm
            recovery={true}
            value={newPassword}
            errorLabel={t("Invalid Password")}
            requiredLabel={t("The password is required")}
            requirementLabel={t("PASSWORD REQUIREMENT")}
            validation={newPassword != null && newPassword.length >= 8 && newPassword.length <= 25}
            requirementItems={[
              {
                name: "8 - 25 " + "Characters",
                valid: newPassword != null && newPassword.length >= 8 && newPassword.length <= 25,
              },
              {
                name: "1 " + "Digit",
                valid: newPassword != null && newPassword.length > 0 && /[0-9]/g.test(newPassword),
              },
              {
                name: "1 " + "Uppercase letter",
                valid: newPassword != null && newPassword.length > 0 && /[A-Z]/g.test(newPassword),
              },
              {
                name: "1 " + "Lowercase letter",
                valid: newPassword != null && newPassword.length > 0 && /[a-z]/g.test(newPassword),
              },
              {
                name: "Not contain spaces",
                valid: newPassword != null && newPassword.length > 0 && newPassword.indexOf(" ") === -1,
              },
              {
                name: "Not contain username",
                valid: newPassword != null && newPassword.length > 0,
              },
            ]}
            name="password"
            type="password"
            placeholder="New password"
            className="recovery-input"
            onChange={(e) => onChange(e)}
          >
            <div className="password-strength-bar">
              <span className={`uppercase ${passwordPrivate?.title}`}>{t(passwordPrivate?.title)}</span>
              <Progress showInfo={false} percent={passwordPrivate?.percent} strokeColor={passwordPrivate?.progressColor} strokeWidth="4px" />
            </div>
          </InputValidationForm>
          <InputValidationForm recovery={true} value={confirmNewPassword} errorLabel={t("Password does not match")} validation={confirmNewPassword === newPassword} requiredLabel={t("Please re-enter your password")} type="password" name="confirmation" className="recovery-input" placeholder="Confirm new password" onChange={(e) => setConfirmNewPassword(e.target.value)} />
          <Button classname="forgot-password-btn" color="blue" onClick={() => resetPassword()}>
            <span>{t("Change my password")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
