import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  HideModal() {
    this.props.hideModal();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="error-modal">
        <div className="modal-dialog modal-dialog-error w3-animate-top">
          <div className="modal-content-deposit modal-content-error">
            <div className="modal-header-deposit modal-header-error">
              <button
                className="close"
                type="button"
                onClick={() => {
                  this.HideModal();
                }}
              >
                <i className="fas fa-times"></i>
              </button>
              <h1 className="modal-title-deposit error-modal-title">
                {this.props.title}
              </h1>
            </div>
            <div className="modal-body-deposit">
              <div className="modal-error-body">
                <div className="alert-error center">
                  <i className="far fa-times-circle close-error"></i>
                </div>
                <div className="message center margin20">
                  {t(this.props.error)}
                </div>
                <button
                  className="btn primary padding-20 w100 margin20"
                  style={{ height: "41px" }}
                  onClick={() => {
                    this.HideModal();
                  }}
                >
                  <b>{t("CLOSE")}</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ErrorModal);
