export const SET_SHOW_GAME = "SET_SHOW_GAME";
export const SET_ERROR_PAGE = "SET_ERROR_PAGE";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";
export const SET_WEBSITE_MODE = "SET_WEBSITE_MODE";
export const SET_SIDEBAR_MENU = "SET_SIDEBAR_MENU";
export const SET_IS_PROMOTIONS = "SET_IS_PROMOTIONS";
export const SET_REGISTER_MODAL = "SET_REGISTER_MODAL";
export const SET_IS_CASHIER_MODE = "SET_IS_CASHIER_MODE";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD"
