import { strongPassword, perfectPassword, mediumPassword } from "./index";
import { currencies } from "../mock/RegistrationModal";
export const isValid = function () {
  if (this.state?.steps === 0) {
    return (
      this.state?.password &&
      this.state?.password.length >= 8 &&
      this.state?.password.length <= 25 &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state?.email) &&
      this.state?.ask &&
      this.state?.currency &&
      this.state?.currency.length > 0 &&
      currencies.indexOf(this.state?.currency) !== -1
    );
  } else if (this.state?.steps === 1) {
    return (
      this.state?.firstname &&
      this.state?.firstname.length > 0 &&
      this.state?.lastname &&
      this.state?.lastname.length > 0 &&
      this.state?.phone &&
      this.state?.phone.length > 0 &&
      !isNaN(this.state?.phone) &&
      this.state?.year &&
      this.state?.year > 0 &&
      this.state?.month &&
      this.state?.month > 0 &&
      this.state?.day &&
      this.state?.day > 0
    );
  } else if (this.state?.steps === 2) {
    return (
      this.state?.city &&
      this.state?.city.length > 0 &&
      this.state?.address &&
      this.state?.address.length > 0 &&
      this.state?.postCode &&
      this.state?.postCode.length > 0 &&
      this.state?.country &&
      this.state?.country.length > 0
    );
  }
};

export const dobvalidation = function (value, type) {
  if (type === "day")
    if (!value)
      this.setState({
        dayvalidation: "error",
        dateofbirthvalidation: "can't be blank",
      });
    else {
      this.setState({ dayvalidation: "" });
    }
  if (type === "month")
    if (!value)
      this.setState({
        monthvalidation: "error",
        dateofbirthvalidation: "can't be blank",
      });
    else {
      this.setState({ monthvalidation: "" });
    }
  if (type === "year")
    if (!value)
      this.setState({
        yearvalidation: "error",
        dateofbirthvalidation: "can't be blank",
      });
    else {
      this.setState({ yearvalidation: "" });
    }
  if (type === "country")
    if (!value) this.setState({ countryvalidation: "error" });
    else {
      this.setState({ countryvalidation: "" });
    }
  if (type === "currency")
    if (!value) this.setState({ currencyvalidation: "error" });
    else {
      this.setState({ currencyvalidation: "" });
    }
  if (type === "ask")
    if (!value)
      this.setState({
        askvalidation: "can't be blank",
      });
    else {
      this.setState({ askvalidation: "" });
    }
  if (
    ((type === "day" && !!value) || this.state?.day) &&
    ((type === "year" && !!value) || this.state?.year) &&
    ((type === "month" && !!value) || this.state?.month) &&
    this.state.isdatevalidated
  )
    this.setState({ dateofbirthvalidation: "" });
};

export const passwordProgress = function () {
  perfectPassword.test(this.state?.password)
    ? this.setState({
        passwordPrivate: {
          title: "perfect",
          percent: 100,
          progressColor: "#8fc118",
        },
      })
    : strongPassword.test(this.state?.password)
    ? this.setState({
        passwordPrivate: {
          title: "strong",
          percent: 70,
          progressColor: "#1db7e7",
        },
      })
    : mediumPassword.test(this.state?.password)
    ? this.setState({
        passwordPrivate: {
          title: "weak",
          percent: 50,
          progressColor: "#fbd01f",
        },
      })
    : this.setState({
        passwordPrivate: {
          title: "awful",
          percent: 20,
          progressColor: "#e06262",
        },
      });
};

export const stepValidation = function (step) {
  return this.state?.steps === step ? "active" : step <= this.state.steps ? "done" : "";
};

export const emailValidation = function (email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const passwordValidation = function (t) {
  return [
    {
      name: "8 - 25 " + t("Characters"),
      valid: this.state.password != null && this.state.password.length >= 8 && this.state.password.length <= 25,
    },
    {
      name: "1 " + t("Digit"),
      valid: this.state.password != null && this.state.password.length > 0 && /[0-9]/g.test(this.state.password),
    },
    {
      name: "1 " + t("Uppercase letter"),
      valid: this.state.password != null && this.state.password.length > 0 && /[A-Z]/g.test(this.state.password),
    },
    {
      name: "1 " + t("Lowercase letter"),
      valid: this.state.password != null && this.state.password.length > 0 && /[a-z]/g.test(this.state.password),
    },
    {
      name: t("Not contain spaces"),
      valid: this.state.password != null && this.state.password.length > 0 && this.state.password.indexOf(" ") == -1,
    },
    {
      name: t("Not contain username"),
      valid: this.state.password != null && this.state.password.length > 0 && this.state.password != this.state.username,
    },
  ];
};
