import React, { useEffect, useState, useContext } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import * as Controller from "../../../helpers/Controller";
import { SharedButton as Button } from "../../shared/Button/index";
import { AccountContext } from "../../../contexts/AccountContext";

import paymentBanner from "../../../img/payment/payments-banner.jpg";
import LoginModal from "../../Modal/LoginModal/index.js";
import RegisterModal from "../../Modal/RegisterModal/index.js";
import { DepositModal } from "../../Modal/DepositModal/index";

import "./index.css";

export const Payment = (props) => {
  const { t } = useTranslation();

  const { account } = useContext(AccountContext);
  const [showModal, setShowModal] = useState(null);
  const [depositMethods, setDepositMethods] = useState();
  const [withdrawMethods, setWithdrawMethods] = useState();

  useEffect(() => {
    Controller.GetPaymentConfiguration("deposit").then((data) => setDepositMethods(data));
    Controller.GetPaymentConfiguration("withdraw").then((data) => setWithdrawMethods(data));
  }, []);

  const paymentData = [
    {
      title: "Deposit Methods",
      type: "deposit",
      data: depositMethods,
    },
    {
      title: "Withdraw Methods",
      type: "withdraw",
      data: withdrawMethods,
    },
  ];

  const paymentMethodsColumn = (type) => {
    const columns = [
      {
        title: "Payment Method",
        dataIndex: "",
        key: "x-img",
        render: (row) => {
          return (
            <div className="payment-image-wrapper">
              <img src={row?.logo?.light} alt="Payment Method image" className="payment-image" />
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "showName",
        key: "Type",
      },
      {
        title: "Fee",
        dataIndex: "Fee",
        key: "Fee",
      },
      {
        title: "Processing Time",
        dataIndex: "Processing Time",
        key: "Processing Time",
      },
      {
        title: "Limits Per Transaction",
        dataIndex: "",
        key: "x-limit",
        render: (row) => {
          return <>{type == "deposit" ? <span>{`Min. €${row?.minDeposit} Max €${row?.maxDeposit}`}</span> : <span>{`Min. €${row?.minWithdraw} Max €${row?.maxWithdraw}`}</span>}</>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        align: "right",
        render: (row) => (
          <Button color="red" size="lg" onClick={() => chooseFunction(type)}>
            {type === "deposit" ? t("DEPOSIT") : t("WITHDRAW")}
          </Button>
        ),
      },
    ];

    return columns;
  };

  const openModal = (modal) => setShowModal(modal);

  const hideModal = () => setShowModal(null);

  const showLogin = () => openModal(<LoginModal hideModal={hideModal} doLogin={props.doLogin} showRegister={showRegister} />);

  const showRegister = () => openModal(<RegisterModal pageSize={props.pageSize} hideModal={hideModal} showLogin={showLogin} />);

  const openDepositModal = () => openModal(<DepositModal hideModal={hideModal} account={account} />);

  const chooseFunction = (type) => {
    if (!account) {
      return showLogin();
    }

    if (account && account?.id && type === "deposit") {
      return openDepositModal();
    }

    if (account && account?.id && type === "withdraw") {
      window.location.href = "/profile/withdraw";
    }
  };

  return (
    <>
      {showModal}
      <div className="payment">
        <div className="payment-banner" style={{ backgroundImage: `url(${paymentBanner})` }}>
          <p className="payment-banner-title">{t("Payments")}</p>
          <p className="payment-banner-subtitle">{t("bannerSubtitle")}</p>
        </div>
        <div className="payment-methods-container">
          {paymentData?.map((payments, index) => (
            <div key={index} className="payment-method">
              <p className="payment-method-title">{payments.title}</p>
              {props?.pageSize?.width > 767 ? (
                <Table dataSource={payments.data} columns={paymentMethodsColumn(payments.type)} className="payment-methods-table" pagination={false} />
              ) : (
                <div>
                  {payments?.data?.map((payment, index) => (
                    <div key={index} className="mobile-payment-card">
                      <div className="mobile-payment-header">
                        <div className="mobile-header-wrapper">
                          <img src={payment?.logo?.light} alt="Payment image" />
                        </div>
                      </div>
                      <div className="card-row">
                        <span>Type</span>
                        <span>{payment.showName}</span>
                      </div>
                      <div className="card-row">
                        <span>Fee</span>
                        <span></span>
                      </div>
                      <div className="card-row">
                        <span>Proccessing Time</span>
                        <span></span>
                      </div>
                      <div className="card-row">
                        <span>Limits Per Transaction</span>
                        {payments.type == "deposit" ? <span>{`Min. €${payment?.minDeposit} Max €${payment?.maxDeposit}`}</span> : <span>{`Min. €${payment?.minWithdraw} Max €${payment?.maxWithdraw}`}</span>}
                      </div>
                      <div className="card-row button-container">
                        <Button color="red" size="sm" onClick={() => chooseFunction(payments.type)}>
                          {payments.type === "deposit" ? t("DEPOSIT") : t("WITHDRAW")}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
