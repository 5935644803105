import {
  SET_SHOW_GAME,
  SET_ERROR_PAGE,
  SET_ACTIVE_TAB,
  SET_LOGIN_MODAL,
  SET_SIDEBAR_MENU,
  SET_WEBSITE_MODE,
  SET_IS_PROMOTIONS,
  SET_REGISTER_MODAL,
  SET_IS_CASHIER_MODE,
  SET_CHANGE_PASSWORD,
} from "../action-types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  showGame: null,
  isDarkMode: true,
  isErrorPage: null,
  isPromotion: null,
  isSidebarMenu: null,
  isCashierMode: null,
  activeTab: "/profile",
  showLoginModal: false,
  isPasswordChange: null,
  showRegisterModal: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CHANGE_PASSWORD: {
      return {
        ...state,
        isPasswordChange: payload,
      };
    }

    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }

    case SET_SHOW_GAME: {
      return {
        ...state,
        showGame: payload,
      };
    }

    case SET_ERROR_PAGE: {
      return {
        ...state,
        isErrorPage: payload,
      };
    }

    case SET_SIDEBAR_MENU: {
      return {
        ...state,
        isSidebarMenu: payload,
      };
    }

    case SET_REGISTER_MODAL: {
      return {
        ...state,
        showRegisterModal: !state.showRegisterModal,
      };
    }

    case SET_LOGIN_MODAL: {
      return {
        ...state,
        showLoginModal: !state.showLoginModal,
      };
    }

    case SET_IS_PROMOTIONS: {
      return {
        ...state,
        isPromotion: payload,
      };
    }

    case SET_IS_CASHIER_MODE: {
      return {
        ...state,
        isCashierMode: payload,
      };
    }

    case SET_WEBSITE_MODE: {
      return {
        ...state,
        isDarkMode: payload,
      };
    }

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["isErrorPage"],
};

export const persistedReducer = persistReducer(persistConfig, reducer);

export default reducer;
