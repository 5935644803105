import React, { useEffect, useState } from "react";
import ErrorModal from "./Modal/ErrorModal";
import AlertModal from "./Modal/AlertModal.jsx";
import * as Controller from "../helpers/Controller";

const Activate = (props) => {
  const [active, setActive] = useState({ message: null, error: null });

  const { id } = props?.match?.params;

  useEffect(() => {
    Controller.CheckVerification(id).then(
      (data) => {
        if (Boolean(data))
          setActive({
            message: "Your account has been successfully activated",
          });
      },
      (error) => {
        setActive({ error: error });
      }
    );
  }, []);

  const hideAlertModal = () => props.history.push("/");

  return (
    <>
      {active?.message != null ? (
        <AlertModal title={"Info"} message={active?.message} hideModal={hideAlertModal} />
      ) : active?.error != null ? (
        <ErrorModal title={"Warning"} error={active?.error} hideModal={hideAlertModal} />
      ) : (
        <div className="loading">
          <i className="spinner fa-spin"></i>
        </div>
      )}
    </>
  );
};

export default Activate;
