export const getShowGame = (state) => state.showGame;
export const getActiveTab = (state) => state.activeTab;
export const getErrorPage = (state) => state.isErrorPage;
export const getWebsiteMode = (state) => state.isDarkMode;
export const getIsPromotion = (state) => state.isPromotion;
export const getLoginModal = (state) => state.showLoginModal;
export const getSidebarMenu = (state) => state.isSidebarMenu;
export const getIsCashierMode = (state) => state.isCashierMode;
export const getRegisterModal = (state) => state.showRegisterModal;
export const getIsChangePassword = (state) => state.isPasswordChange;
