import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { persistedReducer } from "./reducers";

const setMiddleware = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return composeEnhancers(applyMiddleware());
};

const store = createStore(persistedReducer, setMiddleware());

const persistor = persistStore(store);

export { persistor, store };
