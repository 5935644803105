import React, { Suspense, useEffect, useState, useLayoutEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./css/icofont.min.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import dotenv from "dotenv";
import Cookies from "universal-cookie";
import $ from "jquery";
import * as Controller from "./helpers/Controller";

import "./css/w3.css";
import "./css/all.css";
import "./css/reset.css";
import "./css/style.css";
import Activate from "./components/Activate.jsx";
import { Recovery } from "./components/pages/RecoveryAccount";
import { Promotions } from "./components/pages/Promotions/Promotions.jsx";
import { Payment } from "./components/pages/Payment";
import { ConfigurationContextProvider } from "./contexts/ConfigurationContext";
import { pageNameFormater, getActiveUrlArr } from "./helpers/index.js";
import { getConfiguration } from "./helpers/configuration.helper";
import { AccountContextProvider } from "./contexts/AccountContext";
import { setIsPromotion, setIsCashierMode } from "./store/action-creators/index.jsx";
import { getIsPromotion, getIsCashierMode } from "./store/selectors/index.jsx";
import { urlFormater } from "./helpers";
dotenv.config();

const Rules = React.lazy(() => import("./components/pages/Rules.jsx"));
const ForgotPassword = React.lazy(() => import("./components/pages/ForgotPassword"));
const Layout = React.lazy(() => import("./components/Layout").then((module) => ({ default: module.Layout })));
const Casino = React.lazy(() => import("./components/Casino/Casino").then((module) => ({ default: module.Casino })));
const UserLayout = React.lazy(() => import("./components/UserPages/").then((module) => ({ default: module.UserLayout })));
const NotFound = React.lazy(() => import("./components/ErrorPage/index").then((module) => ({ default: module.NotFound })));

const App = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const isPromotions = useSelector(getIsPromotion);
  const isCashierMode = useSelector(getIsCashierMode);


  const [gameId, setGameId] = useState();
  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [configurations, setConfigurations] = useState([]);
  const [sizes, setSizes] = useState({ width: window.innerWidth, height: window.innerHeight });

  const [account, setAccount] = useState(JSON.parse(localStorage.getItem("account")));

  useEffect(() => {
    window.addEventListener("resize", setNewSize);
    setNewSize();

    return () => window.removeEventListener("resize", setNewSize)
  }, []);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let btag = params.get("btag");
    if (btag && btag.length > 3) {
      const cookies = new Cookies();
      cookies.set("referal", { btag: btag, date: new Date() }, { path: "/" });
    }

    GetWebSitePreference();

    $(".clearable").each(() => {
      const $inp = $(this).find("input:text"),
        $cle = $(this).find(".clearable__clear");

      $inp.on("input", () => {
        $cle.toggle(!!this.value);
      });

      $cle.on("touchstart click", (e) => {
        e.preventDefault();
        $inp.val("").trigger("input");
      });
    });
  }, []);

  useEffect(() => {
    const activeGamePath = location.pathname.split("/");
    switch (activeGamePath[1]) {
      case "casino": {
        setGameId(0);
        break;
      }

      case "": {
        setGameId(0);
        break;
      }

      case "livecasino": {
        setGameId(1);
        break;
      }

      default:
        break;
    }

    document.title = `${process.env.REACT_APP_NAME} ${pageNameFormater(location.pathname)}`;
  }, [location]);

  useEffect(() => {
    if (configuration) {
      dispatch(setIsPromotion(configuration?.promotions));
      // dispatch(setIsCashierMode(configuration?.payment));
      dispatch(setIsCashierMode(true));
    }
  }, [configuration]);

  useEffect(() => {
    if (configuration) {
      if (sizes?.width > 768 && configuration?.mobile) {
        changeConfig(configurations);
        return;
      }

      if (sizes?.width < 768 && !configuration?.mobile) {
        changeConfig(configurations);
        return;
      }
    }
  }, [sizes?.width]);

  useEffect(() => {
    if (configuration?.sections.length) {
      urlFormater(configuration?.sections[gameId]);
    }
  }, [configuration]);



  const setNewSize = () => setSizes({ width: window.innerWidth, height: window.innerHeight })

  const changeConfig = (data) => {
    let config = getConfiguration(data);
    setConfiguration({ ...config });
    setConfigurations([...data]);
  };

  const doLogin = (username, password) => {
    if (username != null && password != null) {
      setLoading(true);

      return new Promise(async (resolve, reject) => {
        Controller.Login(username, password).then(
          (data) => {
            if (data !== null) {
              localStorage.setItem("account", JSON.stringify(data));
              //localStorage.setItem(window.location.hostname + appName, JSON.stringify(data));
              setLoading(false);
              setAccount({ ...data });

              resolve(data);
            }
          },
          (error) => {
            setLoading(false);
            reject(error);
          }
        );
      });
    }
  };

  const GetWebSitePreference = () => {
    Controller.GetWebSiteConfiguration().then(
      (data) => {
        changeConfig(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const GetRoute = (section, id) => {
    switch (section.path) {
      case "sportlive":
        break;
      // case "payments":
      //   break;
      case "promotions":
        if (configuration && configuration?.promotions) {
          return (
            <>
              <Route
                key={id}
                exact
                path="/promotions"
                render={(routeProps) => <Promotions routeProps={routeProps} pageSize={sizes} />}
                {...props}
              />
              <Route path="/promotions/:name" render={(routeProps) => <Promotions routeProps={routeProps} pageSize={sizes} />} {...props} />
            </>
          );
        }
        return;
      default:
        return (
          <Route
            key={id}
            path={"/" + section?.path}
            exact
            render={(routeProps) => (
              <Casino
                key={id}
                {...routeProps}
                section={section}
                account={account}
                doLogin={doLogin}
                pageSize={sizes}
                isPromotions={isPromotions}
              />
            )}
          />
        );
    }
  };

  if (configurations === null || configurations?.length === 0)
    return (
      <div className="loading">
        <i className="spinner fa-spin" />
      </div>
    );
  else {
    return (
      <Suspense
        fallback={
          <div className="loading">
            <i className="spinner fa-spin"></i>
          </div>
        }
      >
        <ConfigurationContextProvider configuration={configuration}>
          <AccountContextProvider account={account}>
            <Switch>
              <Route
                path="/profile"
                render={({ location }) =>
                  account && account?.id ? (
                    <UserLayout
                      pageSize={sizes}
                      account={account}
                      location={location}
                      isPromotions={isPromotions}
                      isCashierMode={isCashierMode}
                      doLogin={doLogin}
                    />
                  ) : (
                    <Redirect to="/not-found" component={<NotFound />} />
                  )
                }
              />
              <Route
                path="/users/:page"
                render={(routeProps) =>
                  account && account?.id ? (
                    <UserLayout
                      {...routeProps}
                      pageSize={sizes}
                      account={account}
                      isPromotions={isPromotions}
                      isCashierMode={isCashierMode}
                      doLogin={doLogin}
                    />
                  ) : (
                    <Redirect to="/not-found" component={<NotFound />} />
                  )
                }
              />
              <Layout
                pageSize={sizes}
                account={account}
                isPromotions={isPromotions}
                isCashierMode={isCashierMode}
                section={configuration?.homepage}
                doLogin={doLogin}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(routeProps) => (
                      <Casino
                        key={routeProps}
                        pageSize={sizes}
                        account={account}
                        isPromotions={isPromotions}
                        section={configuration?.homepage}
                        doLogin={doLogin}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/payments"
                    render={(routeProps) => (
                      <Payment
                        {...routeProps}
                        pageSize={sizes}
                        doLogin={doLogin}
                      />
                    )}
                  />
                  <Route component={Activate} {...props} exact path="/activate/:id" />
                  <Route
                    path="/password/new"
                    render={(routeProps) => <ForgotPassword {...routeProps} account={account} doLogin={doLogin} />}
                  />
                  {!account && !account?.id && <Route exact path="/recovery/:id" render={(routeProps) => <Recovery key={routeProps} />} />}
                  <Route component={Rules} {...props} path="/page/:page" />
                  <Route
                    exact
                    path="/popup"
                    render={() => (
                      <div className="loading">
                        <i className="spinner fa-spin" />
                      </div>
                    )}
                  />

                  {getActiveUrlArr().length >= 3 &&
                    (getActiveUrlArr()[1] === "" ||
                      getActiveUrlArr()[1] === "casino" ||
                      getActiveUrlArr()[1] === "livecasino" ||
                      getActiveUrlArr()[2].includes("search=")) ? (
                    <Route
                      path="/:gameType?/:category?/:categoryType?"
                      render={(routeProps) => (
                        <Casino
                          {...routeProps}
                          pageSize={sizes}
                          account={account}
                          isPromotions={isPromotions}
                          section={configuration?.sections[gameId]}
                          doLogin={doLogin}
                        />
                      )}
                    />
                  ) : (
                    <Switch>
                      {configuration && configuration?.sections.map((section, index) => GetRoute(section, index))}
                      <Route path="*" render={() => <NotFound />} />
                    </Switch>
                  )}
                </Switch>
              </Layout>
            </Switch>
          </AccountContextProvider>
        </ConfigurationContextProvider>
      </Suspense>
    );
  }
};

export default App;
