export const transactionsHistoryData = [
  {
    date: "Octember 24, 2021, 09:15:27",
    status: "Accepted",
    action: "Deposit",
    paymentSystem: "Skrill",
    amount: "20 EUR",
  },
  {
    date: "September 21, 2021, 19:12:27",
    status: "Pending",
    action: "Purchase Cryptocurrency",
    paymentSystem: "Utorg",
    amount: "50 EUR",
  },
  {
    date: "Octember 24, 2021, 09:15:27",
    status: "Accepted",
    action: "deposit",
    paymentSystem: "Skrill",
    amount: "20 EUR",
  },
  {
    date: "September 21, 2021, 19:12:27",
    status: "Pending",
    action: "Purchase Cryptocurrency",
    paymentSystem: "Utorg",
    amount: "50 EUR",
  },
];

export const actions = [
  { id: 3, text: "Deposit" },
  { id: 4, text: "Cashout" },
];

export const transactionStatuses = ["Pending", "Accepted", "Discarded"];
export const transactionActions = {
  3: "Deposit",
  4: "Cashout",
};


export const transactionCurrencies = [
  {
    label: "FIAT",
    value: [
      "AUD - Australian Dollar",
      "BRL - Brazilian Real",
      "CAD - Canadian Dollar",
      "CZK - Czech Koruna",
      "EUR - Euro",
      "HUF - Hungarian Forint",
      "JPY - Japanese Yen",
      "NOK - Norwegian Krone",
      "NZD - New Zealand Dollar",
      "PLN - Polish Złoty",
      "RUB - Russian Ruble",
      "USD - United States Dollar",
      "ZAR - South African Rand",
    ],
  },
  {
    label: "CRYPTO",
    value: ["BCH - Bitcoin Cash", "BTC - Bitcoin", "DOG - Dogecoin", "ETH - Ethereum", "LTC - Litecoin", "USDT - Tether"],
  },
];

export const filterDate = [
  { time: "", text: "All", id: "*" },
  { time: "24 ", text: "24 Hours", id: "24" },
  { time: "48 ", text: "48 Hours", id: "48" },
  { time: "3 ", text: "3 Days", id: "72" },
  { time: "1 ", text: "1 Week", id: "168" },
  { time: "1 ", text: "1 Month", id: "720" },
  { time: "3 ", text: "3 Months", id: "2160" },
];


