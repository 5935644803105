import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import * as Controller from "../../../helpers/Controller";
import RegisterModal from "../../Modal/RegisterModal/index.js";
import footerImg from "../../../img/promotion-image/promo-6.jpg";
import { BonusTermsModal } from "../../Modal/BonusTermsModal.jsx";
import { SharedButton as Button } from "../../shared/Button/index";
import "./Promotions.css";

export const Promotions = ({ routeProps, pageSize }) => {
  const { t } = useTranslation();

  const [name, setName] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [gameModal, setGameModal] = useState(null);
  const [promotionData, setPromotionData] = useState();
  const [activePromotion, setActivePromotion] = useState();

  useEffect(() => {
    Controller.GetPromotions().then(
      (data) => {
        try {
          setPromotionData(data);
        } catch {}
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const hideModal = () => {
    setGameModal(null);
  };

  const showModal = () => {
    const account = JSON.parse(localStorage.getItem("account"));
    if (account && account?.id > 0 && account?.type === 0) {
      ////
    } else {
      document.body.scrollTop = 0;
      setGameModal(<RegisterModal pageSize={pageSize} hideModal={hideModal} />);
    }
  };

  const openBonusModal = () => {
    document.body.scrollTop = 0;
    setIsOpen(true);
  };

  useEffect(() => {
    if (routeProps && promotionData) {
      setName(routeProps?.match?.params?.name);
      const activePromotionPage =
        promotionData &&
        promotionData.filter((item) => {
          return item?.title.replace(" ", "-").toLowerCase() === routeProps?.match?.params?.name;
        })[0];
      setActivePromotion({ ...activePromotionPage });
    }
  }, [routeProps, promotionData]);

  return (
    <>
      <BonusTermsModal isOpen={isOpen} setIsOpen={setIsOpen} />
      {gameModal}
      {promotionData && (
        <>
          {!name ? (
            <div id="1" className="promotion-container">
              <div className="promotion-content">
                {/* <div className="promotion-header-container">
                    <img src={promotionHeaderImg} alt="HeaderImg" />
                    <div className="promotion-header-content">
                      <span>
                        {t("WELCOME PACKAGE")} <br />{" "}
                        {t("$/€200 + 200 FREE SPINS")}
                      </span>
                    </div>
                  </div> */}
                {promotionData &&
                  promotionData.map((promotion, index) => (
                    <div className="promotion-card" key={index}>
                      <img src={promotion?.banner} alt={promotion.title} />
                      <div className="promotion-card-container">
                        <span className="promotion-card-title">{t(promotion?.title)}</span>
                        <span className="promotion-card-content">{t(promotion?.label)}</span>
                        <div className="promotion-button-section">
                          <div className="promotion-get-btn" onClick={() => showModal()}>
                            {t("GET BONUS")}
                          </div>
                          <NavLink to={`/promotions/${promotion?.title.replace(" ", "-").toLowerCase()}`} className="promotion-info-btn">
                            {t("MORE INFO")}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="promotion-footer-container">
                <div className="promotion-footer-title">{t("Loyalty program")}</div>
                <div className="promotion-footer-content">
                  <img src={footerImg} alt="footer-img" />
                  <div className="promotion-footer-text">
                    <h2 className="promotion-footer-content-title">{process.env.REACT_APP_BRAND_NAME + t(" MISSIONS")}</h2>
                    <span className="promotion-footer-content-subtitle">
                      {t(`Complete all missions and claim rewards totaling $/€150,000!`)}
                    </span>
                    <div className="promotion-footer-btn-section">
                      <span className="promotion-footer-info-btn" onClick={() => showModal()}>
                        {t("MORE INFO")}
                      </span>
                      <span className="promotion-footer-bonus-btn" onClick={() => openBonusModal()}>
                        {t("BONUS TERMS AND CONDITIONS")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="promotion-page-container">
              <div className="promotion-page">
                <div className="promotion-page-header-container">
                  <img src={activePromotion?.banner} alt="banner" />
                  <div className="promotion-page-header-content">
                    <div className="promotion-page-header-text">
                      <span className="title">{t(activePromotion?.title)}</span>
                      <span className="subtitle">{t(activePromotion?.label)}</span>
                    </div>
                    <div className="promotion-bonus-card">
                      {Object.keys(activePromotion?.bonus).map((key, index) => (
                        <div key={index} className="bonus-card-row">
                          <p className="bonus-name">{t(key)}</p>
                          <p className="bonus-value">{t(activePromotion?.bonus[key])}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="promotion-bonus-info-container">
                    <div className="promotion-bonus-info-content">
                      <span className="title">{t(activePromotion?.title)}</span>
                      <div className="text">{ReactHtmlParser(activePromotion?.description)}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <Button color="red" size="lg-2" onClick={showModal}>
                        {t("GET BONUS")}
                      </Button>
                      <span className="bonus-terms-button" onClick={() => openBonusModal()}>
                        {t("Terms & Conditions")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
