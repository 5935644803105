import React, { Component } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import "react-phone-number-input/style.css";
import { Select, Progress } from "antd";
import Cookies from "universal-cookie";
import { Checkbox } from "antd";
import dotenv from "dotenv";

import ErrorModal from "../../Modal/ErrorModal";
import PhoneInput from "react-phone-number-input";
import AlertModal from "../../Modal/AlertModal.jsx";
import * as Controller from "../../../helpers/Controller";
// import getCountries from "../../../services/getCountries";
import InputValidationForm from "../../InputValidationForm.jsx";
import { registarationModalTextFormater } from "../../../helpers/index";
import { countries, currencies } from "../../../mock/RegistrationModal";
import { ConfigurationContext } from "../../../contexts/ConfigurationContext";
import { isValid, dobvalidation, stepValidation, emailValidation, passwordProgress, passwordValidation } from "../../../helpers/validation";

import "./RegisterModal.css";

dotenv.config();
const { Option } = Select;

class RegisterModal extends Component {
  static contextType = ConfigurationContext;

  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      steps: 0,
      day: null,
      parent: 0,
      ask: false,
      city: null,
      year: null,
      phone: null,
      email: null,
      month: null,
      error: null,
      valid: false,
      message: null,
      address: null,
      lastname: null,
      postCode: null,
      password: null,
      continue: false,
      phoneCode: null,
      firstname: null,
      showCode: false,
      dayvalidation: "",
      askvalidation: "",
      isSubmitted: false,
      yearvalidation: "",
      monthvalidation: "",
      countryvalidation: "",
      country: countries[0],
      isdatevalidated: false,
      registerloading: false,
      currency: currencies[0],
      currencyvalidation: "",
      dateofbirthvalidation: "",
      passwordPrivate: {
        percent: 20,
        title: "AWFUL",
        status: "exception",
      },
    };

    this.isValid = isValid.bind(this);
    this.dobvalidation = dobvalidation.bind(this);
    this.stepValidation = stepValidation.bind(this);
    this.emailValidation = emailValidation.bind(this);
    this.passwordProgress = passwordProgress.bind(this);
    this.passwordValidation = passwordValidation.bind(this);

    this.toBack = this.toBack.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.hideErrorModal = this.hideErrorModal.bind(this);
    this.hideAlertModal = this.hideAlertModal.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    let affiliateKey = cookies.get("affiliate");

    if (affiliateKey != null) {
      Controller.GetAffiliate(affiliateKey).then((data) => {
        if (data != null) this.setState({ parent: data.id });
      });
    }
  }

  HideModal() {
    this.props.hideModal();
  }

  OpenLogin() {
    this.props.hideModal();
    this.props.showLogin();
  }

  hideErrorModal() {
    this.setState({ error: null });
  }

  hideAlertModal() {
    this.setState({ message: null });
    this.HideModal();
  }

  onChange(e, space) {
    if (e?.target?.value.indexOf(" ") === -1 || space) this.setState({ [e?.target?.name]: e?.target?.value });
    else e.target.value = e?.target?.value.slice(0, -1);
    this.passwordProgress();
  }

  getYears() {
    const now = new Date();
    let year = now.getFullYear() - 18;

    let years = [];
    for (let i = 0; i < 100; i++)
      years.push(
        <option key={i} value={year - i}>
          {year - i}
        </option>
      );

    return years;
  }

  getDays(year, month) {
    let lastDay = 31;

    if (year != null && month != null) lastDay = new Date(year, month, 0).getDate();
    else if (month != null && month === 2) lastDay = 29;

    let days = [];
    for (let i = 1; i <= lastDay; i++)
      days.push(
        <option key={i} value={i}>
          {i}
        </option>
      );

    return days;
  }

  onStep(step) {
    if (this.state?.steps >= step) this.setState({ steps: step });
  }

  onContinue() {
    this.setState({ isSubmitted: true });
    const steps = this.state?.steps + 1;
    if (steps === 2) {
      this.setState({ isdatevalidated: true });

      if (!this.state?.day || !this.state?.year || !this.state?.month) this.setState({ dateofbirthvalidation: "can't be blank" });

      if (!this.state?.day) {
        this.setState({ dayvalidation: "error", dateofbirthvalidation: "can't be blank" });
      } else {
        this.setState({ dayvalidation: "" });
      }

      if (!this.state.month) {
        this.setState({ monthvalidation: "error", dateofbirthvalidation: "can't be blank" });
      } else {
        this.setState({ monthvalidation: "" });
      }

      if (!this.state?.year) {
        this.setState({ yearvalidation: "error", dateofbirthvalidation: "can't be blank" });
      } else {
        this.setState({ yearvalidation: "" });
      }
    }

    if (steps == 3) {
      if (!this.state?.country) {
        this.setState({ countryvalidation: "error" });
      } else {
        this.setState({ countryvalidation: "" });
      }

      if (!this.state?.currency) {
        this.setState({ currencyvalidation: "error" });
      } else {
        this.setState({ currencyvalidation: "" });
      }

      if (!this.state?.ask) {
        this.setState({ askvalidation: "Please Accept the Terms And Conditions" });
      } else {
        this.setState({ askvalidation: "" });
      }
    }

    if (steps < 3 && this.isValid()) {
      this.setState({ steps: steps, continue: true, dateofbirthvalidation: "", isSubmitted: false });
    } else if (this.isValid()) {
      this.Register();
    }
  }

  toBack() {
    this.setState({ steps: this.state?.steps - 1 });
  }

  Register() {
    this.setState({ registerloading: true, dateofbirthvalidation: "" });
    const info = {
      nickname: "",
      isSubmitted: false,
      ask: this.state?.ask,
      city: this.state?.city,
      email: this.state?.email,
      zip: this.state?.postCode,
      parent: this.state?.parent,
      address: this.state?.address,
      country: this.state?.country,
      lastName: this.state?.lastname,
      username: this.state?.username,
      password: this.state?.password,
      currency: this.state?.currency,
      firstName: this.state?.firstname,
      mobile: `${this.state?.phoneCode}${this.state?.phone}`,
      birthDate: new Date(this.state?.year, this.state?.month, this.state?.day).toJSON(),
    };

    Controller.RegisterPlayer(info).then(
      (data) => {
        this.setState({ registerloading: false });
        this.SendVerificationMail(data);
      },
      (error) => {
        this.setState({ error: error, registerloading: false });
      }
    );
  }

  SendVerificationMail(account) {
    const message = {
      emailObject: "Please activate you account",
      emailMessage:
        "Welcome to " +
        process.env.REACT_APP_NAME +
        ", Please click on the following link to verify your email and activate your account. " +
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/activate/{code}",
    };

    Controller.SendVerification(account?.id, message).then(
      (data) => {
        this.setState({
          message: "You will soon receive an email with the activation link",
        });
      },
      (error) => {
        this.setState({ error: error });
      }
    );
  }

  render() {
    const { t } = this.props;

    const { username, password, email, firstname, lastname, city, address, phoneCode, phone, postCode } = this.state;

    if (this.state?.error != null) return <ErrorModal title={"Warning"} error={this.state?.error} hideModal={this.hideErrorModal} />;

    if (this.state?.message != null) return <AlertModal title={"Info"} message={this.state?.message} hideModal={this.hideAlertModal} />;

    if (this.state?.message == null && this.state?.error == null) {
      const registrationModalTexts = registarationModalTextFormater(this.context?.configuration?.registration?.subTitle);

      return (
        <div className="w3-container">
          <div style={{ display: "block" }} className="w3-modal">
            <div className="w3-modal-content register-container w3-animate-top w3-card-4">
              <div className="register-left-container">
                <div style={{ backgroundImage: `url(${this.context?.configuration?.registration?.imagePath})` }}>
                  {this.props?.pageSize?.width > 768 ? (
                    <div className="header-text">
                      <span className="header-text-title">{t(this.context?.configuration?.registration?.title)}</span>
                      <span className="header-text-subtitle">{t(registrationModalTexts?.textFirstPart)}</span>
                      <span className="spins">{t(registrationModalTexts?.textSecondPart)}</span>
                    </div>
                  ) : (
                    <div className="header-text">
                      <span className="spins">{t("2 Casino")}</span>
                      <span className="header-text-title">{t(this.context?.configuration?.registration?.title)}</span>
                      <span className="d-flex justify-content-center align-items-center">
                        <span className="header-text-subtitle">{t(registrationModalTexts?.textFirstPart)}</span>
                        <span className="mobile-registration-modal-fs">{t("+ 200 FS")}</span>
                      </span>
                    </div>
                  )}
                </div>
                {this.props?.pageSize?.width <= 768 && (
                  <div className="mobile-register-modal-title">
                    <p className="register-title">{t("REGISTRATION")}</p>
                  </div>
                )}
                <ul>
                  <li className={this.stepValidation(0)}>{this.props?.pageSize?.width > 768 && t("Login Details")}</li>
                  <li className={this.stepValidation(1)}>{this.props?.pageSize?.width > 768 && t("Personal info")}</li>
                  <li className={this.stepValidation(2)}>{this.props?.pageSize?.width > 768 && t("Address info")}</li>
                  <li>
                    <span className="last-step">
                      <span className="last-step-header">{t(this.context?.configuration?.registration?.title)}</span>
                      <span className="last-step-spins">
                        {t(registrationModalTexts?.textFirstPart + " + 200 FS")}
                      </span>
                      <span className="last-step-welcome">{t("Welcome to ") + process.env.REACT_APP_BRAND_NAME}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="right-container">
                <header className="w3-container relative">
                  <span className="w3-button w3-display-topright" onClick={() => this.HideModal()}>
                    <CloseOutlined />
                  </span>
                  {this.props?.pageSize?.width > 768 && <p className="register-title">{t("REGISTRATION")}</p>}
                </header>
                <div className="w3-container relative modal-content">
                  <div className="modal-body">
                    <div className="tab-content">
                      <div className={"tab-pane" + (this.state?.steps === 0 ? " active" : "")}>
                        <InputValidationForm
                          value={email}
                          label={t("Email")}
                          errorLabel={t("Invalid Email")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("can't be blank")}
                          validation={this.emailValidation(email)}
                          type="email"
                          name="email"
                          onChange={(e) => this.onChange(e)}
                        />

                        <InputValidationForm
                          value={password}
                          label={t("Password")}
                          isValid={this.state.isSubmitted}
                          errorLabel={t("Invalid Password")}
                          requiredLabel={t("can't be blank")}
                          requirementLabel={t("PASSWORD REQUIREMENT")}
                          requirementItems={this.passwordValidation(t)}
                          validation={password != null && password.length >= 8 && password.length <= 25}
                          name="password"
                          type="password"
                          className="password"
                          onChange={(e) => this.onChange(e)}
                        >
                          {password && (
                            <div className="password-strength-bar">
                              <span className={`uppercase ${this.state?.passwordPrivate?.title}`}>
                                {t(this.state?.passwordPrivate?.title)}
                              </span>
                              <Progress
                                showInfo={false}
                                percent={this.state?.passwordPrivate?.percent}
                                strokeColor={this.state?.passwordPrivate?.progressColor}
                                strokeWidth="4px"
                              />
                            </div>
                          )}
                        </InputValidationForm>

                        <label className="margin20">{t("Currency")}</label>
                        <Select defaultValue={currencies[0]} className="country-select" onChange={(e) => this.setState({ currency: e })}>
                          {currencies.map((e, index) => (
                            <Option key={index} value={e}>
                              {t(e)}
                            </Option>
                          ))}
                        </Select>
                        <div className="accept-container">
                          <Checkbox
                            onChange={(e) => {
                              this.setState({ ask: e?.target?.checked });
                              this.dobvalidation(e?.target?.checked, "ask");
                            }}
                          >
                            {t("I am 18 years old and I accept the")}
                            <a href="/page/terms-conditions">{t("Terms and Conditions")}</a>
                            {t("and")}
                            <a href="/page/security-privacy">{t("Privacy Policy")}</a>*
                          </Checkbox>
                        </div>
                        <p className="ask-validation">{t(this.state?.askvalidation)}</p>
                      </div>
                      <div className={"tab-pane" + (this.state?.steps == 1 ? " active" : "")}>
                        <label>{t("Date of Birth")}</label>
                        <div className="grid-wrap account-forms birthday-form">
                          <div className="grid-cell relative">
                            <input
                              placeholder={t("dd")}
                              className={"dropdown day " + this.state?.dayvalidation}
                              type="number"
                              defaultValue=""
                              onChange={(e) => {
                                this.dobvalidation(e?.target?.value, "day");
                                this.setState({ day: parseInt(e?.target?.value) });
                              }}
                            />
                          </div>
                          <div className="grid-cell relative">
                            <input
                              placeholder={t("mm")}
                              className={"dropdown month " + this.state?.monthvalidation}
                              type="number"
                              defaultValue=""
                              onChange={(e) => {
                                this.dobvalidation(e?.target?.value, "month");
                                this.setState({ month: parseInt(e?.target?.value) });
                              }}
                            />
                          </div>
                          <div className="grid-cell relative">
                            <input
                              placeholder={t("yyyy")}
                              className={"dropdown year " + this.state?.yearvalidation}
                              type="number"
                              defaultValue=""
                              onChange={(e) => {
                                this.dobvalidation(e?.target?.value, "year");
                                this.setState({ year: parseInt(e?.target?.value) });
                              }}
                            />
                          </div>
                        </div>
                        <div className="date-validation">
                          <span className="select-validation-error">{t(this.state?.dateofbirthvalidation)}</span>
                        </div>

                        <label className="margin20">{t("Mobile Phone")}</label>
                        <div className="phone-container d-flex align-items-start">
                          <PhoneInput
                            value={phoneCode}
                            countryCallingCodeEditable={false}
                            international
                            defaultCountry="US"
                            onChange={(e) => this.setState({ phoneCode: e })}
                          />
                          <InputValidationForm
                            value={phone}
                            placeholder={t("Number")}
                            isValid={this.state?.isSubmitted}
                            requiredLabel={t("can't be blank")}
                            validation={phone != null && !isNaN(phone)}
                            errorLabel={t("is too short (minimum is 5 characters)")}
                            type="text"
                            name="phone"
                            onChange={(e) => this.onChange(e)}
                          />
                        </div>
                        <InputValidationForm
                          value={firstname}
                          label={t("First Name")}
                          placeholder={t("First Name")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("can't be blank")}
                          type="text"
                          name="firstname"
                          onChange={(e) => this.onChange(e, true)}
                        />
                        <InputValidationForm
                          value={lastname}
                          label={t("Last name")}
                          placeholder={t("Last name")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("can't be blank")}
                          type="text"
                          name="lastname"
                          onChange={(e) => this.onChange(e, true)}
                        />
                      </div>
                      <div className={"tab-pane" + (this.state?.steps === 2 ? " active" : "")}>
                        <label>{t("Country")}</label>
                        <Select defaultValue={countries[0]} onChange={(e) => this.setState({ country: e })}>
                          {countries.map((e, index) => (
                            <Option key={index} value={e}>
                              {t(e)}
                            </Option>
                          ))}
                        </Select>

                        <InputValidationForm
                          value={city}
                          label={t("City")}
                          placeholder={t("City")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("The City is required")}
                          type="text"
                          name="city"
                          onChange={(e) => this.onChange(e, true)}
                        />

                        <InputValidationForm
                          value={address}
                          label={t("Address")}
                          placeholder={t("Address")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("The Address is required")}
                          type="text"
                          name="address"
                          onChange={(e) => this.onChange(e, true)}
                        />

                        <InputValidationForm
                          value={postCode}
                          label={t("Postal code")}
                          placeholder={t("Postal code")}
                          isValid={this.state?.isSubmitted}
                          requiredLabel={t("Postcode is required")}
                          type="text"
                          name="postCode"
                          onChange={(e) => this.onChange(e, true)}
                        />
                      </div>
                      <div className={this.state?.showCode ? "" : "collapse"} style={{ margin: "20px" }}>
                        <input type="text" placeholder={t("Reference Code")} />
                      </div>
                      <div className="buttons right">
                        {this.state.steps >= 1 && (
                          <button
                            className="btn primary uppercase w60 back-button continue-button btn-font14 height-41"
                            onClick={this.toBack}
                          >
                            <b>
                              {this.state?.steps < 2 ? (
                                t("Back")
                              ) : this.state?.registerloading ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                              ) : (
                                t("Back")
                              )}
                            </b>
                          </button>
                        )}
                        <button className="btn primary uppercase w60 continue-button btn-font14 height-41" onClick={this.onContinue}>
                          <b>
                            {this.state?.steps < 2 ? (
                              t("Next")
                            ) : this.state?.registerloading ? (
                              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                              t("REGISTRATION")
                            )}
                          </b>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="w3-container relative">
                  <div className="text-center are-you-register">
                    {t("Already have an account?")}
                    <span onClick={() => this.OpenLogin()}>{t("Log in")}</span>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(RegisterModal);
