import React, { Component } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { withTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import {  toast } from "react-toastify";

import "./LoginModal.css";

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      loginError: null,
      loginloading: false,
      usernamevalidation: "",
      passwordvalidation: "",
    };

    this.username = null;
    this.password = null;
  }

  setUsername(e) {
    this.usernameBox = e?.target;

    if (!e?.target?.value) this.setState({ usernamevalidation: "The username is required" });
    else this.setState({ usernamevalidation: "" });
    if (e?.target?.value.indexOf(" ") === -1) this.username = e?.target?.value;
    else e.target.value = e?.target?.value.slice(0, -1);
  }

  setPassword(e) {
    if (!e?.target?.value) this.setState({ passwordvalidation: "The password is required" });
    else this.setState({ passwordvalidation: "" });

    if (e?.target?.value.indexOf(" ") === -1) this.password = e?.target?.value;
    else e.target.value = e?.target?.value.slice(0, -1);
  }

  handleLogin() {
    if (!this.password) this.setState({ passwordvalidation: "The password is required" });
    else this.setState({ passwordvalidation: "" });
    if (!this.username) this.setState({ usernamevalidation: "The username is required" });
    else this.setState({ usernamevalidation: "" });
    this.setState({ loginloading: true });

    const { t } = this.props;

    if (this.username && this.password)
      this.props.doLogin(this.username, this.password).then(
        () => {
          this.setState({ loginError: null });
          this.props.hideModal();
        },
        (error) => {
          toast.error(t("Invalid username or password"), {
            draggable: true,
            autoClose: 5000,
            theme: "colored",
            closeOnClick: true,
            progress: undefined,
            position: "top-right",
            hideProgressBar: false,
          });
          this.setState({
            loginloading: false,
          });
        }
      );
    else this.setState({ loginError: "", loginloading: false });
  }

  handleLoginOnSumbit(e) {
    if (e && e?.keyCode === 13) {
      this.handleLogin();
    }
  }

  OpenRegister() {
    this.props.hideModal();
    this.props.showRegister();
  }

  OpenForgot() {
    this.props.hideModal();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="w3-container">
          <div className="w3-modal">
            <div className="w3-modal-content w3-animate-top w3-card-4">
              <div className="modal-header">
                <span className="w3-button w3-display-topright" onClick={() => this.props.hideModal()}>
                  <CloseOutlined />
                </span>
              </div>
              <div className="modal-title-padding">
                <p className="center">{t("Login")}</p>
              </div>
              <div className="w3-container relative modal-content">
                <div className="modal-body login-modal-body">
                  <label>{t("E-mail")}</label>
                  <input
                    placeholder={t("Enter your email")}
                    className={this.state?.usernamevalidation && "error"}
                    required
                    type="text"
                    name="username"
                    onChange={(e) => this.setUsername(e)}
                    onKeyUp={(e) => this.handleLoginOnSumbit(e)}
                  />
                  <span className="field-validation-error">{t(this.state?.usernamevalidation)}</span>
                  <label className="margin20">{t("Password")}</label>
                  <input
                    placeholder={t("Enter your password")}
                    className={this.state?.passwordvalidation && "error"}
                    required
                    type="password"
                    name="password"
                    onChange={(e) => this.setPassword(e)}
                    onKeyUp={(e) => this.handleLoginOnSumbit(e)}
                  />
                  <span className="field-validation-error">{t(this.state?.passwordvalidation)}</span>
                  <div className="buttons center margin20">
                    <div className="forgot-password center">
                      <Link className="forgot-password-link" to="/password/new" onClick={() => this.OpenForgot()}>
                        {t("Forgot your password?")}
                      </Link>
                    </div>
                    <button className="btn login-btn uppercase full height-41" onClick={() => this.handleLogin()}>
                      {this.state?.loginloading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          &nbsp; {t("LOGIN IN PROGRESS...")}
                        </>
                      ) : (
                        <span>{t("Login")}</span>
                      )}
                    </button>
                    <button className="btn registr-btn uppercase full height-41" onClick={() => this.OpenRegister()}>
                      {t("REGISTRATION")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(LoginModal);
