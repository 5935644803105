import React, { useState } from "react";

const InputValidationForm = (props) => {
  const {
    type,
    name,
    value,
    label,
    isValid,
    children,
    onChange,
    recovery,
    className,
    validation,
    errorLabel,
    placeholder,
    requiredLabel,
    requirementArea,
    requirementLabel,
    requirementItems,
  } = props;

  const [isRequirementArea, setIsRequirementArea] = useState(false);

  const validate = () => {
    if (value == null && !isValid) {
      return "";
    } else if ((value && value?.length) == 0 || (isValid && value == null)) return requiredLabel;

    if (validation != null && !validation) {
      return errorLabel;
    }

    return "";
  };

  let validationLabel = validate();

  return (
    <div className={`${validationLabel && validationLabel?.length > 0 ? "error" : ""}${recovery ? "recovery-pass-input-container" : ""}`}>
      {label && <label className="margin20">{label}</label>}
      <input
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={!value ? "" : value}
        required
        className={
          className +
          " " +
          (value && validationLabel && validationLabel?.length > 0 && recovery && "border-err") +
          " " +
          (!validationLabel && validationLabel?.length === 0 && value && recovery && "border-succes")
        }
        onFocus={() => setIsRequirementArea({ isRequirementArea: true })}
        onBlur={() => setIsRequirementArea({ isRequirementArea: false })}
      />
      {children}
      {!recovery && validationLabel && validationLabel?.length > 0 ? (
        <div className="field-validation-error">
          <div>{validationLabel}</div>
        </div>
      ) : null}
      {requirementLabel && requirementArea ? (
        <div className="input-info">
          <span className="arrow"></span>
          <span className="info-container">
            <span className="info">
              <span className="title">{requirementLabel}</span>
              {requirementItems
                ? requirementItems?.map((item, index) => (
                    <span key={index} className="item">
                      <i className={"fas " + (item?.valid ? "fa-check valid" : "fa-times validation-error")}></i>
                      {" " + item?.name}
                    </span>
                  ))
                : null}
            </span>
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default InputValidationForm;
