import React from "react";
import { Button } from "antd";

import "./index.css";
export const SharedButton = (props) => {
  return (
    <Button
      disabled={props?.disabled}
      className={[props?.color, props?.size, props?.classname, props?.disabled]}
      onClick={() => props?.onClick &&  props?.onClick(props?.data)}
    >
      {props?.children}
    </Button>
  );
};
