import moment from "moment";

export const HandleLogin = (account, func) => {
  if (account != null && account?.balance === 0) func(true);
};

export const getActiveUrlArr = () => window.location.pathname.split("/");

export const getMainCategory = (section) => {
  if (section?.categories != null) {
    for (let i = 0; i < section?.categories?.length; i++) {
      if (section?.categories[i]?.order === 0) {
        return section?.categories[i];
      }
    }
  }

  return null;
};

export const providersOptionsFormating = (section) => {
  return section?.providers
    ? section.providers.map((item) => ({
        value: item?.name,
        label: item?.name,
        logo: item?.logo,
      }))
    : [];
};

export const whenisLiveCasion = (section) => {
  const isVirtual = window.location.pathname.includes("virtual");
  const isLiveCasino = window.location.pathname.includes("livecasino");

  if (isLiveCasino || (isVirtual && section)) {
    return true;
  }

  return false;
};

export const dateFormat = (date) => {
  const dateWithMilisecont = new Date(date);

  const year = dateWithMilisecont?.getFullYear();
  const month = dateWithMilisecont?.getMonth();
  const day = dateWithMilisecont?.getDate();

  return `${year}-${month}-${day}`;
};

export const dateFormatWithSelect = (date) => {
  const dateWithMilisecont = new Date(date);
  const year = dateWithMilisecont?.getFullYear();
  const month = dateWithMilisecont?.toLocaleString("default", {
    month: "long",
  });
  const day = dateWithMilisecont?.getDate();

  return {
    year,
    month,
    day,
  };
};

export const capitalize = (word) => {
  const capitalizeText = word?.split(" ");
  const firstWord = capitalizeText[0];
  const secondWord = capitalizeText[1];
  switch (capitalizeText.length) {
    case 1:
      return firstWord[0]?.toUpperCase() + firstWord?.slice(1);
    case 2:
      return firstWord[0]?.toUpperCase() + firstWord?.slice(1) + " " + secondWord[0]?.toUpperCase() + secondWord?.slice(1);
    default:
      return;
  }
};

export const registarationModalTextFormater = (registartionModalText) => {
  const textList = registartionModalText.split(" ");
  const textFirstPart = textList[0] + " ";
  const textSecondPart = `${textList[1]} ${textList[2]} ${textList[3]} ${textList[4]} `;
  return {
    textFirstPart,
    textSecondPart,
  };
};

export const getActiveCategoty = (section, activeCategory) => {
  if (window.location.pathname.split("/").includes("category") && activeCategory?.length > 2 && activeCategory[3] !== "popular") {
    for (let i = 0; i < section?.categories?.length; i++) {
      if (section?.categories[i]?.name === capitalize(activeCategory[3]?.replaceAll("-", " "))) {
        return {
          title: section?.categories[i]?.title,
          name: section?.categories[i]?.name,
        };
      }
    }
  } else if (activeCategory?.length === 2) {
    return {
      title: "Most Popular",
      name: "Slot Most Popular",
    };
  } else {
    return {
      title: "",
      name: "",
    };
  }
};

export const strongPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

export const perfectPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

export const mediumPassword = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export const documentStatus = (id) => {
  switch (id) {
    case 0:
      return "rejected";
    case 1:
      return "pending";
    case 2:
      return "accepted";
    default:
      break;
  }
};

export const dateFormatConverter = (date) => {
  return moment(date)?.format("MMMM D, YYYY HH:mm");
};

export const pageNameFormater = (path) => {
  let activePageName = "";
  const activeUrlList = path?.split("/");
  switch (activeUrlList.length) {
    case 2:
    case 4:
      activePageName = activeUrlList[1].replaceAll("-", " ");
      return `| ${activePageName && capitalize(activePageName)}`;
    case 3:
      activePageName = activeUrlList[2].replaceAll("-", " ");
      return `| ${capitalize(activePageName)}`;
    default:
      return `| Casino`;
  }
};

export const setActiveSection = (sectionName) => {
  const casinoActiveTab = {
    type: "category",
    title: "All",
    name: "All Slot",
  };

  const liveCasinoActiveTab = {
    type: "category",
    title: "Live Roulette",
    name: "Live Roulette",
  };
  const liveBlackjackTab = {
    name: "Live Blackjack",
    title: "Live Blackjack",
    type: "category",
  };

  switch (sectionName) {
    case "Casino":
      setSessionStorge(casinoActiveTab);
      break;
    case "Live Casino":
      setSessionStorge(liveCasinoActiveTab);
      break;
    case "Live Blackjack":
      setSessionStorge(liveBlackjackTab);
      break;
    case "Live Roulette":
      setSessionStorge(liveCasinoActiveTab);
      break;

    default:
      break;
  }
};

export const setSessionStorge = (payload) => {
  sessionStorage.setItem("activeState", JSON.stringify(payload));
};

export const urlFormater = (configuration) => {
  const path = window.location.pathname.split("/");
  const isHomePage = window.location.pathname === "/";
  const isCasino = window.location.pathname.includes("casino");
  const isLiveCasino = window.location.pathname.includes("livecasino");
  const activeSection = JSON.parse(sessionStorage.getItem("activeState"));
  if (isCasino || isLiveCasino || isHomePage) {
    switch (path?.length) {
      case 2: {
        switch (path[1]) {
          case "":
            setSessionStorge({
              type: "category",
              title: "Popular",
              name: "Slot Most Popular",
            });
            break;
          case "casino":
            setSessionStorge({
              type: "category",
              title: "Most Popular",
              name: "Slot Most Popular",
            });
            break;
          case "livecasino":
            setSessionStorge({
              type: "category",
              title: "Live Roulette",
              name: "Live Roulette",
            });
            break;
          default:
            break;
        }
        break;
      }
      case 3: {
        const activeProviderType = path[2];
        if (activeProviderType?.includes("providers")) {
          const activeProviderIndex = activeProviderType?.split("");
          const activeProvider = configuration?.providers[activeProviderIndex[activeProviderIndex?.length - 1]];
          setSessionStorge({
            type: "provider",
            title: activeProvider?.name,
            name: null,
          });
          window.history.pushState("", "", path[1] + "/providers=" + activeProviderIndex[activeProviderIndex?.length - 1]);
        } else {
          if (activeSection?.name && activeSection?.type) {
            window.history.pushState(
              "",
              "",
              path[1] + "/" + activeSection?.type + "/" + activeSection?.name.replaceAll(" ", "-").toLowerCase()
            );
          } else {
            switch (path[1]) {
              case "casino":
                setSessionStorge({
                  type: "category",
                  title: "Most Popular",
                  name: "Slot Most Popular",
                });
                break;
              case "livecasino":
                setSessionStorge({
                  type: "category",
                  title: "Live Roulette",
                  name: "Live Roulette",
                });
                break;
              default:
                break;
            }
          }
        }
        break;
      }
      case 4: {
        const categoryName = path[3] === "popular" ? "slot-most-popular" : path[3];
        const activeCategory = configuration?.categories?.filter((categry) => {
          const categoryNameFormat = categry?.name.replaceAll(" ", "-").toLowerCase();
          if (categoryNameFormat === categoryName) {
            return categry;
          }
        });
        setSessionStorge({
          type: "category",
          title: activeCategory[0]?.title,
          name: activeCategory[0]?.name,
        });
        break;
      }
      default:
        break;
    }
  }
};
