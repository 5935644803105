import {
  SET_SHOW_GAME,
  SET_ERROR_PAGE,
  SET_ACTIVE_TAB,
  SET_LOGIN_MODAL,
  SET_SIDEBAR_MENU,
  SET_WEBSITE_MODE,
  SET_IS_PROMOTIONS,
  SET_REGISTER_MODAL,
  SET_IS_CASHIER_MODE,
  SET_CHANGE_PASSWORD,
} from "../action-types";

export const setChangePassword = (payload) => ({
  type: SET_CHANGE_PASSWORD,
  payload,
});

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export const setShowGame = (payload) => ({
  type: SET_SHOW_GAME,
  payload,
});

export const setErrorPage = (payload) => ({
  type: SET_ERROR_PAGE,
  payload,
});

export const setSidebarMenu = (payload) => ({
  type: SET_SIDEBAR_MENU,
  payload,
});

export const setRegisterModal = (payload) => ({
  type: SET_REGISTER_MODAL,
  payload,
});

export const setLoginModal = (payload) => ({
  type: SET_LOGIN_MODAL,
  payload,
});

export const setIsPromotion = (payload) => ({
  type: SET_IS_PROMOTIONS,
  payload,
});

export const setIsCashierMode = (payload) => ({
  type: SET_IS_CASHIER_MODE,
  payload,
});

export const setWebsiteMode = (payload) => ({
  type: SET_WEBSITE_MODE,
  payload,
});
